<template>
    <b-overlay :show="formShow" rounded="sm" no-fade>
        <b-card>
            <b-row>
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th scope="col">Team</th>
                        </tr>
                    </thead>
                </table>
            </b-row>

            <validation-observer ref="teamFormRules">
                <b-form>
                    <b-row class="mt-2">
                        <!-- Name -->

                        <b-col cols="12" lg="12" md="12" sm="12">
                            <b-form-group label="Team Title" label-for="teamTitle">
                                <validation-provider name="Team Title" #default="{ errors }" rules="required">
                                    <b-form-input id="teamTitle" v-model="teamData.title" :state="errors.length > 0 ? false : null" type="text" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" lg="12" md="12" sm="12">
                            <b-form-group label="Foreman" label-for="foreman">
                                <validation-provider name="Foreman" #default="{ errors }" rules="required">
                                    <v-select input-id="foreman" v-model="teamData.foreman" @input="foremanSelected" :options="foremanList" label="full_name" :clearable="false"> </v-select>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" lg="12" md="12" sm="12">
                            <b-form-group label="Staff" label-for="staff">
                                <v-select v-model="staff" @input="staffSelected" :options="staffList" label="full_name" input-id="staff" :clearable="false"> </v-select>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" lg="12" md="12" sm="12">
                            <b-table
                                striped
                                hover
                                ref="refUserListTable"
                                class="position-relative"
                                :items="teamData.staffList"
                                responsive
                                :small="true"
                                head-variant="dark"
                                :fields="tableColumns"
                                show-empty
                                empty-text="No matching records found"
                            >
                                <!-- Column: Id -->
                                <template #cell(id)="data">
                                    <!--   <b-link :to="{ name: 'customer-edit', params: { id: data.item.id } }" class="font-weight-bold"> #{{ data.value }} </b-link>-->
                                    <strong class="text-primary">#{{ data.value }}</strong>
                                </template>

                                <!-- Column: Name -->
                                <template #cell(name)="data">
                                    {{ data.value }}
                                </template>

                                <!-- Column: Name -->
                                <template #cell(surname)="data">
                                    {{ data.value }}
                                </template>

                                <template #cell(day_rate)="data">
                                    {{ $func.formatPrice(data.value) }}
                                </template>

                                <template #cell(weekend_rate)="data">
                                    {{ $func.formatPrice(data.value) }}
                                </template>

                                <!-- Column: Role -->
                                <template #cell(role)="data">
                                    <b-badge pill :variant="`light-primary`" class="text-capitalize">
                                        {{ data.value }}
                                    </b-badge>
                                </template>

                                <!-- Column: Status -->
                                <template #cell(status)="data">
                                    <b-badge v-if="data.value == 'A'" pill :variant="`light-success`" class="text-capitalize">
                                        Active
                                    </b-badge>

                                    <b-badge v-if="data.value == 'P'" pill :variant="`light-danger`" class="text-capitalize">
                                        Passive
                                    </b-badge>

                                    <b-badge v-if="data.value == 'D'" pill :variant="`danger`" class="text-capitalize">
                                        Deleted
                                    </b-badge>
                                </template>

                                <template #cell(availability)="data">
                                    <b-badge v-if="data.value == 'A'" pill :variant="`light-success`" class="text-capitalize">
                                        Available
                                    </b-badge>

                                    <b-badge v-if="data.value == 'S'" pill :variant="`light-warning`" class="text-capitalize">
                                        Sick
                                    </b-badge>

                                    <b-badge v-if="data.value == 'H'" pill :variant="`info`" class="text-capitalize">
                                        Holiday
                                    </b-badge>

                                    <b-badge v-if="data.value == 'E'" pill :variant="`primary`" class="text-capitalize">
                                        Working
                                    </b-badge>

                                    <b-badge v-if="data.value == 'W'" pill :variant="`danger`" class="text-capitalize">
                                        AWOL
                                    </b-badge>
                                </template>

                                <!-- Column: Actions -->
                                <template #cell(actions)="data">
                                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                                        <template #button-content>
                                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                                        </template>

                                        <b-dropdown-item @click="deleteUser(data.item)" v-show="data.item.status != 'D'">
                                            <feather-icon icon="EditIcon" />
                                            <span class="align-middle ml-50">Delete</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                    <b-row class="mt-5">
                        <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-group label="Select Text Colour" label-for="gradeLeves">
                                <validation-provider #default="{ errors }" name="chartColour">
                                    <verte display="widget" :showHistory="null" v-model="teamData.textColour" model="hex"></verte>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-group label="Select Background Colour" label-for="gradeLeves">
                                <validation-provider #default="{ errors }" name="chartColour">
                                    <verte display="widget" :showHistory="null" v-model="teamData.colour" model="hex"></verte>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-group label="Preview" label-for="gradeLeves">
                                <template>
                                    <b-badge :style="{ 'background-color': teamData.colour, color: teamData.textColour }" class="text-capitalize p-1">
                                        <span>{{ teamData.title ? teamData.title : "Team title" }}</span>
                                    </b-badge>
                                </template>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" class="mt-3">
                            <b-button v-if="teamId == null" type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                                Save
                            </b-button>
                            <b-button v-else type="submit" @click.prevent="formSubmitted" v-show="teamData.status != 'deleted'" variant="primary" class="mr-1 float-right"> Save Changes </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-card>
    </b-overlay>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";

import store from "@/store";
import employeeStoreModule from "../employeeStoreModule";

import { required, alphaNum, email } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.us";
import router from "@/router";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Verte from "verte";
import "verte/dist/verte.css";

export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
        BRow,
        BCol,
        Verte,

        vSelect,
        Cleave,
        ToastificationContent,

        ValidationProvider,
        ValidationObserver,
    },

    directives: {
        Ripple,
        "b-modal": VBModal,
    },

    setup() {
        const EMPLOYEE_APP_STORE_MODULE_NAME = "employee";

        // Register module
        if (!store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) store.registerModule(EMPLOYEE_APP_STORE_MODULE_NAME, employeeStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) store.unregisterModule(EMPLOYEE_APP_STORE_MODULE_NAME);
        });
    },

    data: function() {
        var teamData = {
            id: null,
            foreman: null,
            staffList: [],
            title: null,
            colour: "#000000",
            textColour: "#ffffff",
        };

        return {
            teamData: teamData,

            baseURL: store.state.app.baseURL,
            required,
            alphaNum,
            email,
            staff: null,

            teamId: null,
            formShow: false,
            permissions: [],
            foremanList: [],
            staffList: [],

            tableColumns: [
                { key: "id", sortable: true, class: "text-center" },
                { key: "name", sortable: true, class: "text-center" },
                { key: "surname", sortable: true, class: "text-center" },
                { key: "email", sortable: true, class: "text-center" },
                { key: "role", sortable: true, class: "text-center" },

                { key: "weekend_rate", sortable: true, class: "text-center" },
                { key: "day_rate", sortable: true, class: "text-center" },
                { key: "actions", sortable: false },
            ],

            options: {
                number: {
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                },

                percent: {
                    numeral: true,
                    numeralPositiveOnly: true,
                    blocks: [2],
                    prefix: "% ",
                    rawValueTrimPrefix: true,
                    numeralIntegerScale: 2,
                },
            },
        };
    },

    created() {
        this.getTeamById();
    },

    methods: {
        deleteUser(user) {
            if (user.role == "Staff") {
                this.staffList.push(user);
            } else {
                this.foremanList.push(user);
                this.teamData.foreman = null;
            }
            this.teamData.staffList = this.teamData.staffList.filter((item) => item.id !== user.id);
        },

        foremanSelected() {
            this.teamData.staffList.push(this.teamData.foreman);
            this.foremanList = this.foremanList.filter((item) => item.id !== this.teamData.foreman.id);
        },

        staffSelected() {
            this.teamData.staffList.push(this.staff);
            this.staffList = this.staffList.filter((item) => item.id !== this.staff.id);

            this.staff = null;
        },

        getForeman() {
            store
                .dispatch("employee/foremanDropDown", {})
                .then((response) => {
                    this.foremanList = response.data;
                    this.getStaff();
                })
                .catch((error) => {});
        },
        getStaff() {
            store
                .dispatch("employee/staffDropDown", {})
                .then((response) => {
                    this.staffList = response.data;
                })
                .catch((error) => {
                    this.staffList = [];
                });
            this.formShow = false;
        },

        getTeamById() {
            this.formShow = true;

            if (router.currentRoute.params.id) {
                this.teamId = parseInt(router.currentRoute.params.id);

                store
                    .dispatch("employee/fetchTeam", { id: router.currentRoute.params.id })
                    .then((response) => {
                        if (response.status == 200) {
                            this.teamData.id = response.data.id;
                            this.teamData.staffList = response.data.staff;
                            this.teamData.foreman = response.data.foreman;
                            this.teamData.title = response.data.title;
                            this.teamData.colour = response.data.colour ?? "#000000";
                            this.teamData.textColour = response.data.textColour ?? "#ffffff";

                            this.foremanList = response.data.foremanList;
                            this.staffList = response.data.staffList;

                            this.formShow = false;
                        } else {
                            router.push({ name: "error-404" });
                        }
                    })
                    .catch((error) => {
                        router.push({ name: "error-404" });
                    });
            } else {
                this.getForeman();
            }
        },

        formSubmitted() {
            this.formShow = true;

            this.$refs.teamFormRules.validate().then((success) => {
                if (success) {
                    if (router.currentRoute.params.id) {
                        store.dispatch("employee/updateTeam", this.teamData).then((response) => {
                            if (response.status == 200) {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: "Successful",
                                        text: "✔️ Team Update Successful",
                                        icon: "ThumbsUpIcon",
                                        variant: "success",
                                    },
                                });

                                setTimeout(function() {
                                    this.formShow = false;
                                    router.push({ name: "team-view", params: { id: response.data.id } });
                                }, 500);
                            } else {
                                this.$swal({
                                    title: "Error",
                                    text: "Team update has failed. Please try again or contact support.",
                                    icon: "error",
                                    showCancelButton: false,
                                    confirmButtonText: "OK",
                                });
                            }
                        });
                    } else {
                        store.dispatch("employee/addTeam", this.teamData).then((response) => {
                            if (response.status == 200) {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: "Successful",
                                        text: "✔️ Team Add Successful",
                                        icon: "ThumbsUpIcon",
                                        variant: "success",
                                    },
                                });

                                setTimeout(function() {
                                    this.formShow = false;
                                    router.push({ name: "team-view", params: { id: response.data.id } });
                                }, 500);
                            } else {
                                this.$swal({
                                    title: "Error",
                                    text: "Team update has failed. Please try again or contact support.",
                                    icon: "error",
                                    showCancelButton: false,
                                    confirmButtonText: "OK",
                                });
                            }
                        });
                    }
                } else {
                    this.formShow = false;
                }
            });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.verte__controller {
    display: none;
}
</style>
